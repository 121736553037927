<template>
  <div>
    <titulo-relatorio-totalizadores />
    <b-row>
      <b-col cols="12" md="3" class="mt-3">
        <input-select
          ref="tipoContrato"
          :options="opcoes.tipoContratos"
          v-model="formulario.tipoContrato"
          :label="$t('ACADEMICO.TIPO_CONTRATO')"
          required
        />
      </b-col>
      <b-col cols="12" md="3" class="mt-md-3">
        <input-select-multiple
          ref="anoLetivoId"
          v-model="formulario.anoLetivoId"
          :label="$t('ACADEMICO.ANO_LETIVO')"
          :options="opcoes.anosLetivos"
          required
        />
      </b-col>

      <b-col cols="12" md="3" class="mt-3">
        <input-select-multiple
          ref="cursoId"
          v-model="formulario.cursoId"
          :label="$t('ACADEMICO.CURSO')"
          :options="opcoes.cursos"
          :disabled="desabilitar.curso"
        />
      </b-col>
      <b-col cols="12" md="3" class="mt-3">
        <input-select-multiple
          ref="grupoTurmaId"
          v-model="formulario.grupoTurmaId"
          :label="$t('ACADEMICO.GRUPO_TURMA')"
          :options="opcoes.gruposTurma"
          :disabled="desabilitar.grupoTurma"
        />
      </b-col>

      <b-col cols="12" md="3" class="mt-3">
        <input-select-multiple
          ref="turmaId"
          v-model="formulario.turmaId"
          :label="$t('ACADEMICO.TURMA')"
          :options="opcoes.turmas"
          :disabled="desabilitar.turma"
        />
      </b-col>

      <b-col cols="12" md="auto" class="mt-3">
        <b-button
          variant="info"
          @click="realizarImpressao"
          class="w-100 botao-acao-filtro"
        >
          {{ $t('GERAL.GERAR_RELATORIO') }}
        </b-button>
      </b-col>
    </b-row>
  </div>
</template>

<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import pdf from '@/common/utils/pdf';
import helpers from '@/common/utils/helpers';

// Services:
import AnoLetivoService from '@/common/services/anoLetivo/ano-letivo.service';
import CursoService from '@/common/services/academico/curso.service';
import GrupoTurmaService from '@/common/services/academico/gruposTurma.service';
import TurmaService from '@/common/services/academico/turma.service';
import EnumeradoresService from '@/common/services/enumeradores/enumeradores.service';

// Components:
import { InputSelectMultiple, InputSelect } from '@/components/inputs';
import mensagem from '@/common/utils/mensagem';
import TituloRelatorioTotalizadores from '@/views/academico/relatorios/relatorio-totalizadores/TituloRelatorioTotalizadores';

export default {
  components: {
    TituloRelatorioTotalizadores,
    InputSelectMultiple,
    InputSelect,
  },
  data() {
    return {
      formulario: {
        tipoContrato: null,
        anoLetivoId: null,
        cursoId: null,
        grupoTurmaId: null,
        turmaId: null,
      },
      opcoes: {
        anosLetivos: [],
        cursos: [],
        gruposTurma: [],
        turmas: [],
        tipoContratos: [],
      },
      desabilitar: { curso: true, grupoTurma: true, turma: true },
    };
  },
  watch: {
    'formulario.anoLetivoId': {
      handler(anoLetivoId) {
        if (anoLetivoId) {
          this.getCursos(anoLetivoId.id);
        } else {
          this.opcoes.cursos = [];
          this.opcoes.gruposTurma = [];
          this.opcoes.turmas = [];
          this.desabilitar.curso = true;
          this.desabilitar.grupoTurma = true;
          this.desabilitar.turma = true;
        }
        this.formulario.cursoId = null;
        this.formulario.grupoTurmaId = null;
        this.formulario.turmaId = null;
      },
    },
    'formulario.cursoId': {
      handler(cursoId) {
        if (cursoId) {
          this.getGruposTurmaPorCursoId(cursoId);
        } else {
          this.desabilitar.grupoTurma = true;
          this.desabilitar.turma = true;
          this.opcoes.gruposTurma = [];
          this.opcoes.turmas = [];
        }
        this.formulario.grupoTurmaId = null;
        this.formulario.turmaId = null;
      },
    },
    'formulario.grupoTurmaId': {
      handler(turmaId) {
        if (turmaId) {
          this.getTurmaPorGrupoTurmaIdAnoLetivoId();
        } else {
          this.desabilitar.turma = true;
          this.opcoes.turmas = [];
        }
        this.formulario.turmaId = null;
      },
    },
  },
  mounted() {
    this.getTipoContratos();
    this.getAnosLetivos();
  },
  methods: {
    validarFormulario() {
      return helpers.validarFormulario(this.$refs);
    },

    realizarImpressao() {
      if (!this.validarFormulario()) return;
      this.$store.dispatch(START_LOADING);
      const { anoLetivoId, cursoId, grupoTurmaId, tipoContrato, turmaId } =
        this.formulario;
      const filtro = {
        anoLetivoId: anoLetivoId.id,
        cursoId: cursoId?.id,
        tipoContrato: tipoContrato,
        grupoTurmaId: grupoTurmaId?.id,
        turmaId: turmaId?.id,
      };
      TurmaService.gerarRelatorioTotalizadoresPdf(filtro)
        .then(({ data }) => {
          if (data.documento) {
            let bytes = pdf.base64ToArrayBuffer(data.documento);

            let blob = new Blob([bytes], { type: 'application/pdf' });

            var link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = data.nomeArquivo;
            link.click();
          }
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },

    // obter dados
    getTipoContratos() {
      this.$store.dispatch(START_LOADING);
      EnumeradoresService.listar('tipo-contratos')
        .then(({ data }) => {
          data = data.map((row) => ({
            // ...row,
            text: row.descricao,
            value: row.id,
          }));
          this.opcoes.tipoContratos = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getAnosLetivos() {
      this.$store.dispatch(START_LOADING);
      AnoLetivoService.listar()
        .then(({ data }) => {
          data = data.map((row) => {
            return {
              ...row,
              text: row.ano,
              value: row.id,
            };
          });

          this.opcoes.anosLetivos = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getCursos(anoLetivoId) {
      this.$store.dispatch(START_LOADING);
      CursoService.obterPorAnoLetivo(anoLetivoId)
        .then(({ data }) => {
          data = data.map((row) => {
            return {
              ...row,
              text: row.descricao,
              value: row.id,
            };
          });

          this.opcoes.cursos = data;
          this.desabilitar.curso = false;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getGruposTurmaPorCursoId(curso) {
      if (this.formulario.grupoTurmaId !== null) {
        this.formulario.grupoTurmaId.value = '';
        this.formulario.grupoTurmaId.text = '';

        this.formulario.turmaId.value = '';
        this.formulario.turmaId.text = '';

        this.desabilitar.turma = true;
      }

      this.$store.dispatch(START_LOADING);
      GrupoTurmaService.obterTodos({ cursoId: curso.id })
        .then(({ data }) => {
          data = data.map((row) => {
            return {
              ...row,
              text: row.descricao,
              value: row.id,
            };
          });

          this.opcoes.gruposTurma = data;
          this.desabilitar.grupoTurma = false;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    getTurmaPorGrupoTurmaIdAnoLetivoId() {
      if (this.formulario.turmaId !== null) {
        this.formulario.turmaId.value = '';
        this.formulario.turmaId.text = '';
      }
      const anoLetivoId = this.formulario.anoLetivoId?.id;
      const grupoTurmaId = this.formulario.grupoTurmaId?.id;

      if (anoLetivoId !== null && grupoTurmaId !== undefined) {
        this.desabilitar.turma = false;
      }

      this.$store.dispatch(START_LOADING);
      TurmaService.buscarPorGrupoTurmaIdAnoLetivoId({
        grupoTurmaId: this.formulario.grupoTurmaId?.id,
        anoLetivoId: this.formulario.anoLetivoId?.id,
      })
        .then(({ data }) => {
          data = data.map((row) => {
            return {
              ...row,
              text: row.descricao,
              value: row.id,
            };
          });

          this.opcoes.turmas = data;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>

<style scoped>
.ajuste-fino-btn {
  margin-top: 28px;
}
</style>
